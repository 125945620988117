import { PublicationMetadata, textOnly } from '@lens-protocol/metadata';
import { useCreatePost } from '@lens-protocol/react-web';
import { KEYSTONE_URL } from 'config';
import { useMutation } from '@apollo/client';
import { Article as TArticle } from 't2-graphql-types';
import { useCallback } from 'react';
import { useReactiveAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { updateLensPublicationIdMutation } from '../mutations/updateLensPublicationIdByArticleId';
import { useProtectActionWithLensLogin } from '../../ConnectWithLensCard/hooks/useProtectActionWithLensLogin';
import { getLensPostContentFromArticle } from '../helpers/getLensPostContentFromArticle';
import { LENS_POST_APP_ID } from '../../../services/lens/constants';
import { getOrbTagsForLensPosts } from '../../../services/orb/getOrbTagsForLensPosts';
import { UNEXPECTED_ERROR_WHEN_POSTING_ON_LENS } from '../constants/constants';

export const uploadJson = async (data: PublicationMetadata) => {
  try {
    const response = await fetch(`${KEYSTONE_URL}/api/lens-post/create`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    });
    const json = await response.json();
    return json.url;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error({ err });
  }
};

export const useCreateLensPost = () => {
  const userRequest = useReactiveAuthenticatedUser();
  const user = userRequest?.data?.user;
  const { execute: createPost, error, loading: isCreatePostLoading } = useCreatePost();
  const [updateLensPublicationId] = useMutation(updateLensPublicationIdMutation);

  const createLensArticlePost = useCallback(
    async (articleData: TArticle) => {
      const postContent = getLensPostContentFromArticle(articleData);
      try {
        const tags = getOrbTagsForLensPosts(articleData);
        const metadata = textOnly({ content: postContent, appId: LENS_POST_APP_ID, tags });
        const metadataUri = await uploadJson(metadata);

        const createPostResponse = await createPost({ metadata: metadataUri });
        const createPostTx = await createPostResponse?.unwrap().waitForCompletion();
        const post = createPostTx?.unwrap();

        return post;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Create Lens post error', { error });
        throw new Error(UNEXPECTED_ERROR_WHEN_POSTING_ON_LENS);
      }
    },
    [createPost, updateLensPublicationId],
  );

  const createLensPostAndLinkWithArticle = useCallback(
    async (articleData: TArticle = {} as TArticle) => {
      const { id, shouldUploadArticleOnLens } = articleData;

      if (!shouldUploadArticleOnLens || !user?.isConnectedWithLens) {
        return;
      }

      const { id: postId } = await createLensArticlePost(articleData);
      await updateLensPublicationId({ variables: { lensPublicationId: postId, articleId: id } });
    },
    [createLensArticlePost, user?.isConnectedWithLens],
  );

  const { execute: protectedCreateLensPost, isPending: isProtectedCreateLensPostPending } =
    useProtectActionWithLensLogin(createLensPostAndLinkWithArticle);

  return {
    createLensPost: protectedCreateLensPost,
    error,
    isPending: isProtectedCreateLensPostPending || isCreatePostLoading,
  };
};
