import { gql } from '@apollo/client';

export const updateLensPublicationIdMutation = gql`
  mutation updateLensPublicationId($articleId: ID!, $lensPublicationId: String!) {
    updateArticle(where: { id: $articleId }, data: { lensPublicationId: $lensPublicationId }) {
      id
      lensPublicationId
    }
  }
`;
