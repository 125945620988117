import omit from 'lodash/omit';
import { ArticleUpdateArgs } from 't2-graphql-types';
import { TArticleEditData } from '../types';

const getTerritoryPayloadData = (formData: TArticleEditData): ArticleUpdateArgs['data']['territory'] =>
  formData.territory?.id ? { connect: { id: formData.territory.id } } : { disconnect: true };

export const getUpdateArticlePayloadData = (
  formData: TArticleEditData,
  useDraftStatus?: boolean,
): Omit<ArticleUpdateArgs['data'], 'author'> => ({
  ...omit(formData, ['territory']),
  ...(formData.territory ? { territory: getTerritoryPayloadData(formData) } : {}),
  ...(useDraftStatus ? { status: 'draft' } : {}),
});
