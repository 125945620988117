import { Profile } from '@lens-protocol/react-web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLoginWithLensViaPrivy } from './useLoginWithLensViaPrivy';
import { useActiveLensProfile } from '../../../services/lens/hooks/useActiveLensProfile';

export type TLensPartialProfile = Pick<Profile, 'id' | 'handle'> | null;

export const useProtectActionWithLensLogin = <TParamData, TReturnValue = void>(
  action: (data: TParamData) => TReturnValue,
) => {
  const { loginWithLens } = useLoginWithLensViaPrivy();
  const { data: activeProfile } = useActiveLensProfile();
  const promiseResolver = useRef<(value: TReturnValue | PromiseLike<TReturnValue>) => void | null>(null);
  const promiseRejector = useRef<(reason?: any) => void>(null);
  const [isDataPending, setIsDataPending] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pendingDataRef = useRef<TParamData | null>(null);

  const actionWithLensLogin = useCallback(
    async (data?: TParamData): Promise<TReturnValue | void> => {
      const returnedPromise = new Promise<TReturnValue>((resolve, reject) => {
        // @ts-ignore
        promiseResolver.current = resolve;
        // @ts-ignore
        promiseRejector.current = reject;
      });

      const profile = await loginWithLens();
      if (!profile) {
        promiseRejector.current?.(new Error('User not logged in'));
      }

      if (!isDataPending) {
        setIsDataPending(true);
        pendingDataRef.current = data || null;
      }

      return returnedPromise;
    },
    [isDataPending, loginWithLens],
  );

  const handlePendingAction = useCallback(async () => {
    const shouldTrigger = !!action && activeProfile && isDataPending && !isRequestPending;

    if (shouldTrigger) {
      setIsRequestPending(true);
      setIsDataPending(false);
      try {
        // @ts-ignore temporarily ignore, typescript error was here before lens v2
        const res = await action(pendingDataRef.current || undefined);
        promiseResolver.current?.(res);
      } catch (error) {
        promiseRejector.current?.(error);
      } finally {
        setIsRequestPending(false);
        setIsLoading(false);
      }
    }
    // TODO: Fix missing `action` dependenciy due to continuous refresh when used with lens likes
  }, [activeProfile, isDataPending, isRequestPending]);

  useEffect(() => {
    handlePendingAction();
  }, [handlePendingAction]);

  return { execute: actionWithLensLogin, isPending: isDataPending || isRequestPending || isLoading };
};
