import React, { FC } from 'react';
import { ConfirmationModalButton } from './ConfirmationModalButton';

type TConfirmationModalActionsProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  isLoading?: boolean;
  confirmationButtonText?: string;
  cancelButtonText?: string;
};

const ConfirmationModalActions: FC<TConfirmationModalActionsProps> = ({
  handleCancel,
  handleConfirm,
  isLoading = false,
  confirmationButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}) => (
  <div className="flex justify-center gap-x-2 md:gap-x-5">
    <ConfirmationModalButton onClick={handleCancel}>{cancelButtonText}</ConfirmationModalButton>
    <ConfirmationModalButton isLoading={isLoading} onClick={handleConfirm} isConfirm>
      {confirmationButtonText}
    </ConfirmationModalButton>
  </div>
);

export default ConfirmationModalActions;
