import { API, OutputData } from '@editorjs/editorjs';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { getReadTimeInMinutesFromBody } from 't2-keystone/src/schema/Article/services/getReadTime';
import { BlockMutationEvent } from '@editorjs/editorjs/types/events/block';
import { Maybe, Territory } from 't2-graphql-types';
import { TArticleEditData, TArticleFormSubmitHandler } from './types';
import { TCustomSelectValue } from '../Select/types';
import sanitizeArticleBlocks from './helpers/sanitizeArticleBlocks';
import { hideConversionToolbarOnInlineToolbarClick } from './helpers/hideConversionToolbarOnInlineToolbarClick';

type TUseArticleEditorFormProps = {
  initialState: TArticleEditData;
  isDataInitialized: boolean;
};

const defaultState: TArticleEditData = {
  title: undefined,
  status: undefined,
  shouldStoreOnArweave: undefined,
  territory: undefined,
  body: {} as OutputData,
};

const getTerritoryStateByTerritory = (territory: Maybe<Territory> | undefined) =>
  territory?.name && territory?.id
    ? {
        id: territory.id,
        value: territory.name,
        label: territory.name,
      }
    : undefined;

export const useArticleEditorForm = ({
  initialState = defaultState,
  isDataInitialized,
}: TUseArticleEditorFormProps) => {
  const territoryInitialState: TCustomSelectValue | undefined = getTerritoryStateByTerritory(initialState?.territory);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [body, setBody] = useState<OutputData>(initialState.body || ({} as OutputData));
  const [title, setTitle] = useState<string>(initialState.title || '');
  const [slug, setSlug] = useState<string>(initialState.slug || '');
  const [slugError, setSlugError] = useState<string>('');
  const [readTime, setReadTime] = useState<string>(initialState.readTime || '0');
  const [coverImage, setCoverImage] = useState<File>();
  const [territory, setTerritory] = useState<TCustomSelectValue | undefined>(territoryInitialState);
  const [status, setStatus] = useState<string | undefined>(initialState.status || undefined);
  const [shouldUploadArticleOnLens, setShouldUploadArticleOnLens] = useState<boolean>(
    !!initialState.shouldUploadArticleOnLens,
  );
  const [shouldStoreOnArweave, setStoreOnArweave] = useState<boolean>(!!initialState.shouldStoreOnArweave);

  const hasBodyChanged = !isEqual(body?.blocks, initialState.body?.blocks);
  const hasTitleChanged = title !== initialState.title;
  const hasTerritoryChanged = (territoryInitialState || territory) && !isEqual(territoryInitialState, territory);
  const hasCoverImageChanged = !!coverImage;
  const hasStatusChanged = status !== initialState.status;
  const hasSlugChanged = slug !== initialState.slug;
  const hasShouldUploadArticleOnLensChanged = shouldUploadArticleOnLens !== !!initialState.shouldUploadArticleOnLens;
  const hasStoreOnArweaveChanged = !!shouldStoreOnArweave !== !!initialState.shouldStoreOnArweave;

  const hasChanged =
    hasBodyChanged ||
    hasTitleChanged ||
    hasCoverImageChanged ||
    hasTerritoryChanged ||
    hasStatusChanged ||
    hasShouldUploadArticleOnLensChanged ||
    hasStoreOnArweaveChanged ||
    hasSlugChanged;

  const getCurrentState = () => ({
    body: hasBodyChanged ? sanitizeArticleBlocks(body) : undefined,
    title: hasTitleChanged ? title : undefined,
    status: hasStatusChanged ? status : undefined,
    slug: hasSlugChanged ? slug : undefined,
    shouldStoreOnArweave: hasStoreOnArweaveChanged ? shouldStoreOnArweave : undefined,
    coverImage: hasCoverImageChanged ? coverImage : undefined,
    territory: hasTerritoryChanged ? { id: territory?.id, name: territory?.value } : undefined,
    shouldUploadArticleOnLens: hasShouldUploadArticleOnLensChanged ? shouldUploadArticleOnLens : undefined,
  });

  const initializeState = (nextState: TArticleEditData) => {
    setTitle(nextState.title || '');
    setBody(nextState.body || ({} as OutputData));
    setSlug(nextState.slug || '');
    setTerritory(getTerritoryStateByTerritory(nextState.territory));
    setStatus(nextState.status || undefined);
    setShouldUploadArticleOnLens(!!nextState.shouldUploadArticleOnLens);
    setStoreOnArweave(!!nextState.shouldStoreOnArweave);
    setReadTime(nextState.readTime || '0');
  };

  const handleSelectTerritory = (territory: TCustomSelectValue) => {
    setTerritory(territory);
  };

  const onTitleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setTitle(e.target.value);
  };

  const onSlugChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSlugError('');
    setSlug(e.currentTarget.value);
  };

  const toggleStatus = (status?: string) => {
    const isPublished = initialState?.status === 'published';
    const nextStatus = status || (isPublished ? 'draft' : 'published');
    setStatus(nextStatus);
  };

  const debouncedSetBody = useRef(
    debounce(async (api: API) => {
      const data = await api.saver.save();
      setBody(data);
      setIsLoading(false);
    }, 300),
  );

  const onBodyChange = async (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => {
    setIsLoading(true);
    await debouncedSetBody.current?.(api);
    hideConversionToolbarOnInlineToolbarClick(event);
  };

  const onSubmit = (handler: TArticleFormSubmitHandler) => async () => {
    setIsLoading(true);
    // @ts-ignore
    await handler(getCurrentState());
    setCoverImage(undefined);
    setIsLoading(false);
  };

  const resetToInitialState = () => {
    initializeState(initialState);
  };

  useEffect(() => {
    if (initialState.title) {
      setTitle(initialState.title);
    }

    if (initialState.body) {
      setBody(initialState.body);
    }

    if (initialState.slug) {
      setSlug(initialState.slug);
    }
  }, [initialState]);

  useEffect(() => {
    if (body) {
      setReadTime(getReadTimeInMinutesFromBody(body).toString());
    }
  }, [body, setReadTime]);

  useEffect(() => {
    setIsInitializing(true);
  }, []);

  useEffect(() => {
    if (isDataInitialized && isInitializing && initialState) {
      initializeState(initialState);
      setIsInitialized(true);
      setIsInitializing(false);
    }
  }, [isDataInitialized, isInitializing, initialState]);

  return {
    body,
    title,
    slug,
    readTime,
    slugError,
    shouldStoreOnArweave,
    handleUpdateStoreOnArweave: setStoreOnArweave,
    setSlugError,
    onTitleChange,
    onSlugChange,
    onSubmit,
    onBodyChange,
    resetToInitialState,
    hasChanged,
    isLoading,
    coverImage,
    setCoverImage,
    territory,
    handleSelectTerritory,
    status,
    toggleStatus,
    shouldUploadArticleOnLens,
    setShouldUploadArticleOnLens,
    isInitializing,
    isInitialized,
  };
};
