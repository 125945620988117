export const enum AnalyticsModalType {
  onboarding = 'onboarding',
  postShare = 'post_share',
  postDelete = 'post_delete',
  postUnpublish = 'post_unpublish',
  joinTerritory = 'join_territory',
  leaveTerrritory = 'leave_territory',
  likes = 'likes',
  postReaders = 'post_readers',
  commentLikes = 'comment_likes',
  commentDelete = 'comment_delete',
  promptShare = 'prompt_share',
  promptDelete = 'prompt_delete',
  promptEdit = 'prompt_edit',
  newTerritory = 'new_territory',
  abandonTerritory = 'abandon_territory',
  deleteTerritory = 'delete_territory',
  search = 'search',
  userSettingsUnsavedChanges = 'user_settings_unsaved_changes',
}
