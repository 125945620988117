import { Article } from 't2-graphql-types';
import kebabCase from 'lodash/kebabCase';

export const getOrbTagsForLensPosts = (articleData: Article) => {
  const territoryName = articleData?.territory?.name;
  if (!territoryName) {
    return undefined;
  }

  const typeTag = articleData.type === 'ARTICLE' ? 'orbcommunities-t2/post' : 'orbcommunities-t2/prompt';
  const orbCommunityTag = `orbcommunities-t2/${kebabCase(territoryName)}`;
  const tags = [typeTag, orbCommunityTag].filter(Boolean) as string[];
  return tags.length ? tags : undefined;
};
