import React, { memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { TDropdownState } from './types';

export interface IDropDownProps {
  menu: React.ReactNode;
  label?: React.ReactNode;
  renderLabel?: ({ isOpen }: TDropdownState) => React.ReactNode;
  position?: 'right' | 'left';
  onStateChange?: (state: TDropdownState) => void;
  menuContainerClassName?: string;
}

const Dropdown: React.FC<IDropDownProps> = memo(
  ({ label, menu, renderLabel, position = 'left', onStateChange, menuContainerClassName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleOpen = () => {
      setIsOpen(true);
      onStateChange?.({ isOpen: true });
    };

    const handleClose = () => {
      if (!isOpen) {
        return;
      }
      setIsOpen(false);
      onStateChange?.({ isOpen: false });
    };

    useEffect(() => {
      const handleClickOutsideOfDropdown = (e: MouseEvent) => {
        if (buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
          handleClose();
        }
      };

      window.addEventListener('click', handleClickOutsideOfDropdown);
      return () => {
        window.removeEventListener('click', handleClickOutsideOfDropdown);
      };
    });

    return (
      <div className="relative">
        <button ref={buttonRef} onClick={isOpen ? handleClose : handleOpen}>
          {label || renderLabel?.({ isOpen })}
        </button>
        {isOpen && (
          <ul
            className={cx(
              'absolute flex flex-col bg-white-900 rounded-[8px] z-[11] max-w-max mt-2 p-2 whitespace-nowrap',
              menuContainerClassName,
              {
                'right-0': position === 'right',
              },
            )}
            onClick={handleClose}
          >
            {menu}
          </ul>
        )}
      </div>
    );
  },
);

export default Dropdown;
